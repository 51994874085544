import React, { useState, useEffect } from 'react';
import { Input } from 'antd';
import { InputProps } from 'antd/es/input';
import { useTranslation } from 'react-i18next';
import errorReporting from 'technical/error-reporting';

const placesRequiredFields = ['address_components', 'geometry.location'];

export interface GeoAddress {
  name: string;
  input: string;
  city: string;
  zipCode: string;
  country: string;
  coordinates: {
    latitude: number;
    longitude: number;
  };
}

interface Props extends Omit<InputProps, 'onChange' | 'value'> {
  value?: GeoAddress;
  onChange?: (val: GeoAddress) => void;
  type?: 'address' | 'city';
  onClear?: () => void;
}

export const emptyGeoAddress: GeoAddress = {
  name: '',
  input: '',
  city: '',
  zipCode: '',
  country: '',
  coordinates: {
    latitude: 0,
    longitude: 0,
  },
};

const AddressInput: React.FC<Props> = ({
  value = emptyGeoAddress,
  onChange,
  type = 'address',
  ...rest
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const inputRef: HTMLInputElement | null = document.querySelector(
    '#adress-input',
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (value.input === '' && searchQuery === '') {
      setSearchQuery('');
    }
  }, [value, searchQuery]);

  useEffect(() => {
    if (inputRef) {
      const autocomplete = new google.maps.places.Autocomplete(inputRef, {
        componentRestrictions: { country: ['fr', 're', 'gp', 'mq', 'gf'] },
        fields: placesRequiredFields,
        types: [type === 'city' ? 'locality' : 'address'],
      });

      const fillInAddress = () => {
        const place = autocomplete.getPlace();
        const { address_components: addressComponent, geometry } = place;

        // Fields here should match the array `placesRequiredFields`
        if (!addressComponent || !geometry || !geometry.location) {
          errorReporting.error(
            new Error(
              `Google places fields, missing field for ${inputRef.value}`,
            ),
          );
          return;
        }

        const placeGeoAddress = { ...emptyGeoAddress };

        addressComponent.forEach(placeElement => {
          const { types, long_name: longName } = placeElement;

          if (types.includes('street_number')) {
            placeGeoAddress.name = `${longName}`;
          }

          if (types.includes('route')) {
            placeGeoAddress.name = `${placeGeoAddress.name} ${longName}`;
          }

          if (types.includes('locality')) {
            placeGeoAddress.city = longName;
          }

          if (types.includes('postal_code')) {
            placeGeoAddress.zipCode = longName;
          }

          if (types.includes('country')) {
            placeGeoAddress.country = longName;
          }
        });

        placeGeoAddress.coordinates = {
          latitude: geometry.location.lat(),
          longitude: geometry.location.lng(),
        };

        if (type === 'city') {
          placeGeoAddress.input = `${placeGeoAddress.city}, ${placeGeoAddress.country}`;
        } else if (type === 'address') {
          placeGeoAddress.input = `${placeGeoAddress.name}, ${placeGeoAddress.city}, ${placeGeoAddress.country}`;
        }

        setSearchQuery(placeGeoAddress.input);
        if (onChange) {
          onChange(placeGeoAddress);
        }
      };

      autocomplete.setFields(['address_component', 'geometry']);
      autocomplete.addListener('place_changed', fillInAddress);
      return () => {
        autocomplete.unbindAll();
      };
    }
    return () => {};
  }, [type, inputRef, onChange]);

  return (
    <>
      <br />
      <Input
        {...rest}
        id="adress-input"
        value={searchQuery}
        style={{ width: 200 }}
        onChange={e => setSearchQuery(e.target.value)}
        placeholder={t(
          `episode.registration.search.algolia-placeholder-${type}`,
        )}
      />
      <br />
    </>
  );
};

export default AddressInput;
